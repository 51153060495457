import React, { useState, useEffect } from 'react';
import { Route, Routes, useNavigate, useParams } from 'react-router-dom';
import io from 'socket.io-client';
import './App.css';


const socket = io("https://pitp-production.up.railway.app", {
    transports: ["polling", "websocket"],
});

export const Home = () => {
    const [room, setRoom] = useState('');
    const [username, setUsername] = useState('');
    const navigate = useNavigate();

    const joinRoom = () => {
        navigate(`/room/${room}?username=${username}`);
    };

    const createRoom = () => {
        socket.emit('createRoom');
        socket.on('roomCreated', (roomId) => {
            navigate(`/room/${roomId}?username=${username}`);
        });
    };

    return (

        <div className="container">
            <h1>Planning Poker</h1>
            <div>
                <input
                    type="text"
                    placeholder="Username"
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                />
                <input
                    type="text"
                    placeholder="Room ID"
                    value={room}
                    onChange={(e) => setRoom(e.target.value)}
                />
                <button onClick={joinRoom}>Join Room</button>
                <button onClick={createRoom}>Create Room</button>
            </div>
        </div>
    );
}

const fibonacciSequence = [0, "1/2", 1, 2, 3, 5, 8, 13, 20, 40, 100, '?', 'PC'];

const Room = () => {
    const { roomId } = useParams();
    const [votes, setVotes] = useState([]);
    const [users, setUsers] = useState([]);
    const [showVotes, setShowVotes] = useState(false);
    const [average, setAverage] = useState(null);
    const [revealedVotes, setRevealedVotes] = useState({});
    const query = new URLSearchParams(window.location.search);
    const username = query.get('username');
    const navigate = useNavigate();

    useEffect(() => {
        socket.emit('joinRoom', { room: roomId, username });

        const updateRoom = (room) => {
            setVotes(Object.entries(room.votes || {}));
            setUsers(room.users || []);
        };

        const updateUsers = (users) => {
            setUsers(users);
        };

        socket.on('updateRoom', updateRoom);
        socket.on('updateUsers', updateUsers);

        socket.on('showAverage', ({ average, votes }) => {
            setAverage(average);
            setRevealedVotes(votes);
            setShowVotes(true);
            setSelectedVote(null)
        });

        socket.on('votesReset', () => {
            setVotes([]);
            setAverage(null);
            setRevealedVotes({});
            setShowVotes(false);
        });


        return () => {
            socket.off('updateRoom', updateRoom);
            socket.off('updateUsers', updateUsers);
            socket.off('showAverage');
        };
    }, [roomId, username]);

    const [selectedVote, setSelectedVote] = useState(null);

    const submitVote = (voteValue) => {
        socket.emit('vote', { room: roomId, vote: voteValue, username });
        setSelectedVote(voteValue);
    };

    const revealVotes = () => {
        socket.emit('revealVotes', roomId);
        console.log(votes);
    };

    const resetVotes = () => {
        socket.emit('resetVotes', roomId);
    };


    const handleDisconnect = () => {
        console.log("quit")
        socket.emit('quit')
        socket.on('quit', () => {});
        navigate(`/`);
    }

    return (
        <>
            <button className='quit-button' onClick={() => handleDisconnect()} >Leave room</button>
            <div className="room-container">
                <div className="vote-section">
                    <h2>Room: {roomId}</h2>
                    <h3>Choose Your Vote:</h3>
                    <div className="card-container">
                        {fibonacciSequence.map((num, index) => (
                            <PokerCard num={num} index={index} selectedVote={selectedVote} onClick={() => submitVote(num)} />
                        ))}
                    </div>
                </div>

                <div className="participants-panel">
                    <h3>Participants and Votes</h3>
                    <ul>
                        {users.map(user => (
                            <li key={user.id}>
                                {user.username}: {showVotes ? revealedVotes[user.username] || 'Not voted' : user.hasVoted ? 'Voted' : 'Not Voted'}
                            </li>
                        ))}
                    </ul>

                    <button onClick={revealVotes}>Reveal Votes</button>

                    <button onClick={resetVotes}>Reset Votes</button>

                    {showVotes && average && (
                        <div className="average-section">
                            <h3>Average Vote: {average}</h3>
                        </div>
                    )}
                    <UserStorySection roomId={roomId} socket={socket} average={average} clearVotes={resetVotes} />
                </div>
            </div>
        </>
    );
};

const PokerCard = ({num, index, selectedVote, onClick}) => {

    const suitSymbols = {
        spades: '♠',
        hearts: '♥',
        clubs: '♣',
        diamonds: '♦',
    };

    const suitsArray = Object.keys(suitSymbols);

    const adjustedIndex = index % suitsArray.length;

    const suit = suitsArray[adjustedIndex];
    const symbol = suitSymbols[suit];

    const isRedSuit = suit === 'hearts' || suit === 'diamonds';

    return (
        <div className={`card ${selectedVote === num ? isRedSuit ? 'selected-black' : "selected-red" : ''}`} key={num} onClick={onClick} >
            <div className="corner-top" style={{color: isRedSuit ? "red": "black"}}>
                {num}
                <span className="suit" style={{color: isRedSuit ? "red": "black"}}>{symbol}</span>
            </div>
            <div className="corner-bottom" style={{color: isRedSuit ? "red": "black"}}>
                {num}
                <span className="suit-rotate" style={{color: isRedSuit ? "red": "black"}}>{symbol}</span>
            </div>
            <div className="dashed-border"></div>
        </div>
    );
};

export const App = () => {
    return (
        // <Router>
            <Routes>
                <Route path="/" exact element={<Home />} />
                <Route path="/room/:roomId" element={<Room />} />
            </Routes>
        // </Router>
    );
}

const UserStorySection = ({ roomId, socket, average, clearVotes }) => {
    const [userStoryInput, setUserStoryInput] = useState(''); 
    const [userStories, setUserStories] = useState([]); 

    useEffect(() => {
        const handleStoryUpdate = (newUserStories) => {
            setUserStories(newUserStories);
        };

        socket.on('updateUserStories', handleStoryUpdate);

        return () => {
            socket.off('updateUserStories', handleStoryUpdate);
        };
    }, [socket]);

    const submitUserStory = () => {
        if (userStoryInput.trim() === '') return; 
        const newStory = { id: Date.now(), text: userStoryInput }; 

        socket.emit('createUserStory', { room: roomId, newStory });

        setUserStoryInput('');
        clearVotes();
    };

    return (
        <div className="user-story-section">
            <h3>User Stories</h3>

            <textarea
                value={userStoryInput}
                onChange={(e) => setUserStoryInput(e.target.value)}
                placeholder="Write a new user story..."
                rows={4}
                style={{ width: '100%', marginBottom: '10px', padding: '10px', borderRadius: '5px', border: '1px solid #ccc' }}
            />

            { userStories.length === 0 ?
            <button 
                onClick={submitUserStory}
                disabled={average ? false : true}
                style={{
                    backgroundColor: '#4CAF50', 
                    color: 'white', 
                    padding: '10px 20px', 
                    border: 'none', 
                    borderRadius: '5px', 
                    cursor: 'pointer'
                }}
            >
                Create User Story
            </button>
            : 
            <button 
                onClick={submitUserStory}
                disabled={average ? false : true}
                style={{
                    backgroundColor: '#4CAF50', 
                    color: 'white', 
                    padding: '10px 20px', 
                    border: 'none', 
                    borderRadius: '5px', 
                    cursor: 'pointer'
                }}
            >
                Next
            </button>
            }

            {userStories.length > 0 && (
                <div className="user-story-list">
                    {userStories.map((story) => (
                        <div 
                            key={story.id} 
                            className="user-story-card" 
                            style={{
                                padding: '20px', 
                                backgroundColor: '#f9f9f9', 
                                borderRadius: '10px', 
                                boxShadow: '0 4px 8px rgba(0,0,0,0.1)', 
                                marginTop: '10px'
                            }}
                        >
                            <p>{story.text}</p>
                            <p>average: {story.average}</p>
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
};
